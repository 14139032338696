@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	src: local('Roboto'), url('assets/fonts/Roboto-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 400;
	font-style: italic;
	src: local('Roboto'), url('assets/fonts/Roboto-Italic.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	src: local('Roboto'), url('assets/fonts/Roboto-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Roboto';
	font-weight: 700;
	font-style: italic;
	src: local('Roboto'), url('assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
	display: flex;
	flex-direction: column;
	justify-content: left;
	align-items: flex-start;
}

html {
	--text-color: #111926;
	--text-color-dark: #05080a;
	--text-color-light: #6B6F7A;
	--primary: #2D275E;
	--primary-dark: #0E0939;
	--primary-light: #473E96;
	--primary-super-light: #eeecf8;
	--white: #FFFFFF;
	--gray: #F9FAFC;
	--border-color: #E6E7E9;
}

* {
	font-family: 'Roboto', Helvetica, Arial, sans-serif;
	box-sizing: border-box;
}

body {
	margin: 0;
}
